
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonAvatar, IonIcon, IonButton, IonButtons, menuController } from "@ionic/vue";
import { ref } from "vue";

import { useRouter } from "vue-router";
import { menu } from "ionicons/icons";
import moment from "moment";
// Generazione QR
import QRCodeVue3 from "qrcode-vue3";

import { dbUser, dbCustomers, dbDocuments, dbRichieste, dbPresenze, dbTimbrature } from "@/services/localbase";
import apiDipendenti from "@/services/dipendenti";

export default {
    name: "Profilo",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonAvatar,
        IonIcon,
        IonButton,
        IonButtons,
        QRCodeVue3,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem("userInfo"));
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
        const userImage = `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;

        const router = useRouter();

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * Set user avatar image
         */
        function setImage(user) {
            if (user.dipendenti_foto) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
            } else {
                return `${process.env.VUE_APP_BASE_URL}/images/user.png`;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (!field || field === "") {
                return " - ";
            } else {
                return field;
            }
        }

        /**
         * ! GESTIONE APERTURA E CHIUSURA CODICE QR DA MOSTRARE COME IMMAGINE
         * ! Generazione barcode
         */

        const randomString = ref("");
        function generateRandomString() {
            const chars = "0123456789";
            let result = "";

            for (let i = 0; i < 5; i++) {
                result += chars.charAt(Math.floor(Math.random() * 5));
            }

            console.log(result);
            return result;
        }

        let tryIndex = 0;
        async function setBarcodeDipendente() {
            if (tryIndex < 3) {
                randomString.value = generateRandomString();
                //const res = await apiDipendenti.setBarcode(dipendenteID, "12345");
                const res = await apiDipendenti.setBarcode(dipendenteID, randomString.value);
                tryIndex++;

                //Trovato un altro dipendente con lo stesso codice gnerato, lo genero nuovamente
                if (res.status === 8) {
                    setBarcodeDipendente();
                }
            } else {
                tryIndex = 0;
                //console.log("tentativi esauriti");
                setBarcodeDipendente();
            }
        }

        const showQrImage = ref(false);
        function openQrImage() {
            showQrImage.value = true;
            setBarcodeDipendente();
        }

        function closeQrImage() {
            showQrImage.value = false;
        }

        /**
         * Force app update
         */
        function updateApp() {
            window.location.reload(true);
        }

        /**
         * Delete localbase CLIENTI and USER database
         */
        async function deleteDb() {
            /*       const deleteUser = await dbUser.collection("user").delete();
      const deleteDocuments = await dbDocuments
        .collection("documents")
        .delete();
      const deleteRichieste = await dbRichieste
        .collection("richieste")
        .delete();
      const deletePresenze = await dbPresenze.collection("presenze").delete();
      const deleteTimbrature = await dbTimbrature
        .collection("timbrature")
        .delete(); */

            dbUser.delete().then((response) => {
                console.log(response);
            });
            dbDocuments.delete().then((response) => {
                console.log(response);
            });
            dbRichieste.delete().then((response) => {
                console.log(response);
            });
            dbPresenze.delete().then((response) => {
                console.log(response);
            });
            dbTimbrature.delete().then((response) => {
                console.log(response);
            });
        }

        /**
         * Delete Localbase databases and clear localStorage
         * Redirect user to Login page
         */
        function logOut() {
            deleteDb().then(() => {
                localStorage.clear();
                router.replace("/login");
            });
        }

        return {
            user,
            setImage,
            userImage,
            dateFormat,
            checkField,
            logOut,
            updateApp,
            menu,
            openMenu,
            //Gestione codice QR image
            showQrImage,
            openQrImage,
            closeQrImage,
            randomString,
        };
    },
};
