import axios from "axios";

export default {
    async setBarcode(dipendenteID: string, barcode: string) {
        const data = new FormData();
        data.append("dipendenti_badge_app", barcode);

        const response = await axios.post(`rest/v1/edit/dipendenti/${dipendenteID}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        console.log(response);
        return response.data;
    },
};
